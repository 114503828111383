import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Grid, Button, TextField, Select, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { Flag, Lan } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import French from "../../Assets/Images/Flag/French.png"
import British from "../../Assets/Images/Flag/British.png"
import Spanish from "../../Assets/Images/Flag/Spanish.png"
import profileIcon from "../../Assets/Images/profileicon.png"
import Uploaded from "../../Assets/Images/Uploaded.png"
import LayerComponent from '../Common/LayerComponent';
import { useNavigate } from "react-router-dom";
import ProfileServices from '../../Services/ProfileServices';
import { getFileUploadData, putprofileData } from '../../Store/action';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/Store';
import { enqueueSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import { LanguageContext } from '../../LanguageContext';
import { setLanguage } from '../../Store/Slice/languageSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    LongTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 16px !important',
            color: '#FFFFFF',
            '&::placeholder': {
                color: "#FFFFFF",
                fontFamily: "Inter",
                fontWeight: 300,
                fontSize: "13px",
            }
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "10px",
            fontSize: "12px",
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        marginLeft: "10px",
        flex: '1',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '10px',
            width: '100%',
        },
    },
    ShortTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 16px !important',
            color: '#FFFFFF',
            '&::placeholder': {
                color: "#FFFFFF",
                fontFamily: "Inter",
                fontWeight: 300,
                fontSize: "13px",
            }
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "10px",
            fontSize: "12px",
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        width: "30%",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: 0,
            marginTop: '10px',
        },
    },
    separator: {
        borderBottom: '1px solid #FFFFFF',
        marginBottom: "24px",
        marginTop: "24px"
    },
    separatorVertical: {
        borderLeft: '1px solid #FFFFFF',
        height: '38px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

interface EstablishmentData {
    logo: string;
    type: string;
    activity: string;
    address: string;
    company_name: string;
    tva_number: string;
}

interface SubscriptionData {
    tag: string;
    price: number;
    validity: number;
    access_level: number;
    date: string;
}

interface profileBodyData {
    email: string;
    phone: string;
    f_name: string;
    l_name: string;
    role: string;
    lang: string;
    establishment: EstablishmentData;
    signup_type: string;
    media_id: string;
    subscription: SubscriptionData;
    id: string;
}

interface profileDataBody {
    data: profileBodyData;
}

interface FileUpload {
    data: string[];
}

const ProfilPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { language, changeLanguage } = useContext(LanguageContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [profileData, setprofileData] = useState<profileDataBody>();
    const [loading, setLoading] = useState(true);
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [logo, setLogo] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [type, setType] = useState('');
    const [address, setAddress] = useState('');
    const [lang, setLang] = useState('');
    const [activity, setActivity] = useState('')
    const [tvaNumber, setTvaNumber] = useState('');
    const [selectedImage, setSelectedImage] = useState<string>();
    const [selectedImageFile, setSelectedImageFile] = useState<File>();
    const languages = useSelector((state: RootState) => state.language.lang);
    const { t, i18n } = useTranslation();

    const {
        tag = 'Free',
        price = 0,
        validity = 0,
        access_level = 0,
        date = '',
    } = profileData?.data.subscription || {};

    useEffect(() => {
        ProfileServices.getProfileData()
            .then(res => {
                setprofileData(res.data);
                setLoading(false);
                setFName(res.data.data.f_name);
                setLName(res.data.data.l_name);
                setEmail(res.data.data.email);
                setPhone(res.data.data.phone);
                setLogo(res.data.data.establishment.logo);
                setCompanyName(res.data.data.establishment.company_name);
                setType(res.data.data.establishment.type);
                setAddress(res.data.data.establishment.address);
                setTvaNumber(res.data.data.establishment.tva_number)
                setLang(res.data.data.lang);
                setActivity(res.data.data.establishment.activity)
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const userData = localStorage.getItem("user");
    const userId = userData ? JSON.parse(userData)?.data?.id : '';

    const handleUpdateProfile = async () => {
        const updatedData = {
            user_id: userId,
            f_name: fName,
            l_name: lName,
            phone: phone,
            lang: lang,
            establishment: {
                logo: logo,
                type: type,
                activity: activity || '',
                address: address,
                company_name: companyName,
                tva_number: tvaNumber,
            }
        };

        if (selectedImageFile) {
            const formData = new FormData();
            formData.append('folderName', 'logo');
            formData.append('files', selectedImageFile, `logoIcon-${Date.now()}.jpg`);

            try {
                const response: FileUpload = unwrapResult(await dispatch(getFileUploadData(formData)));
                const imageUrl = response.data[0];
                updatedData.establishment.logo = imageUrl;
                dispatch(setLanguage(updatedData.lang));
            } catch (err) {
                if (err instanceof Error) {
                    console.error('Error:', err.message);
                    enqueueSnackbar(err.message, { variant: 'error' });
                } else {
                    console.error('An unexpected error occurred');
                    enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
                }
                return;
            }
        }

        try {
            await dispatch(putprofileData(updatedData)).unwrap();
            enqueueSnackbar(t('ProfileUpdateSuccessfully'), { variant: 'success' });
            navigate('/home');
        } catch (err) {
            if (err instanceof Error) {
                console.error('Error:', err.message);
                enqueueSnackbar(err.message, { variant: 'error' });
            } else {
                console.error('An unexpected error occurred');
                enqueueSnackbar(t('AnUnexpectedErrorOccurred'), { variant: 'error' });
            }
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setSelectedImageFile(file);
        }
    };

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: isMobile ? 2 : 3,
            ml: isMobile ? 0 : '240px',
            backgroundColor: '#0F0F0F',
            color: 'white',
            minHeight: '100vh',
            mt: { xs: '45px', sm: 0 },  // Add this line
        }}>
            <Card sx={{
                background: "radial-gradient(59.32% 80.35% at 50% 0%, rgba(26, 26, 26, 0.8) 0%, rgba(13, 13, 13, 0.8) 100%)",
                boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                position: "relative",
                overflow: "hidden",
                borderRadius: '30px',
                border: '1px solid #2E2E2E',
                padding: isMobile ? "15px" : "21px 50px 29px 50px"
            }}>
                <LayerComponent />
                <Box sx={{ position: 'relative', zIndex: '1' }}>
                    <Box alignItems="center" justifyContent="center" flexDirection="column">
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                            <Typography variant="h3" sx={{
                                fontFamily: "BlairITC",
                                lineHeight: isMobile ? "34px" : "44px",
                                color: "#FFFFFF",
                                textTransform: 'uppercase',
                                fontSize: isMobile ? "24px" : "inherit"
                            }}>{t('Profile')}</Typography>
                            <Typography variant="h4" sx={{
                                marginBottom: "0",
                                lineHeight: isMobile ? "16px" : "8px",
                                fontFamily: "Wedding Gothic ATF",
                                color: "#FFFFFF",
                                fontSize: isMobile ? "14px" : "inherit"
                            }}>{t('AccountInfo')}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: 'space-between',
                            width: "100%",
                            gap: isMobile ? "20px" : 0
                        }}>
                            <Box sx={{ position: "relative", alignSelf: isMobile ? "center" : "flex-start" }}>
                                <Box sx={{
                                    overflow: 'hidden',
                                    padding: '5px',
                                    height: isMobile ? "80px" : "100px",
                                    width: isMobile ? "80px" : "100px",
                                    borderRadius: '50%',
                                    backgroundColor: "#2D2D2D",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        src={selectedImage ? selectedImage : (logo !== '' ? `${process.env.REACT_APP_FILE_URL}/logo/${logo}` : profileIcon)}
                                        alt="Nomad Logo"
                                        style={{
                                            width: '100%',
                                            borderRadius: '100%',
                                            objectFit: 'cover',
                                            height: '100%'
                                        }}
                                    />
                                </Box>
                                <Box sx={{
                                    cursor: 'pointer',
                                    position: "absolute",
                                    left: isMobile ? "60px" : "74px",
                                    top: isMobile ? "65px" : "81px",
                                    borderRadius: '50%',
                                    backgroundColor: "#000000",
                                    height: "20px",
                                    width: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img src={Uploaded} alt="Uploaded" style={{ height: "10px", }} />
                                    <input
                                        type="file"
                                        id="image-upload"
                                        accept="image/*"
                                        style={{
                                            opacity: '0',
                                            height: '10px',
                                            width: '10px',
                                            position: 'absolute',
                                        }}
                                        onChange={handleImageChange}
                                    />
                                </Box>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    marginTop: "10px",
                                    fontSize: "11px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>{t('EditLogo')}</Typography>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: isMobile ? "column" : "row",
                                justifyContent: "space-between",
                                width: isMobile ? "100%" : "calc(100% - 120px)",
                                gap: isMobile ? "20px" : "52px"
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? "column" : "row",
                                    alignItems: isMobile ? "flex-start" : "center",
                                    width: isMobile ? "100%" : "400px",
                                }}>
                                    <Typography variant="subtitle2" sx={{
                                        color: '#FFFFFF',
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        marginBottom: isMobile ? "5px" : 0
                                    }}>{t('Name')} :</Typography>
                                    <TextField
                                        id="name"
                                        variant="outlined"
                                        className={classes.LongTextField}
                                        inputProps={{ style: { color: '#FFFFFF', } }}
                                        placeholder='Jean '
                                        value={fName}
                                        onChange={(e) => setFName(e.target.value)}
                                        fullWidth={isMobile}
                                    />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? "column" : "row",
                                    alignItems: isMobile ? "flex-start" : "center",
                                    width: isMobile ? "100%" : "400px",
                                }}>
                                    <Typography variant="subtitle2" sx={{
                                        color: '#FFFFFF',
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        marginBottom: isMobile ? "5px" : 0
                                    }}>{t('FirstName')} :</Typography>
                                    <TextField
                                        id="firstname"
                                        variant="outlined"
                                        className={classes.LongTextField}
                                        inputProps={{ style: { color: '#FFFFFF', } }}
                                        placeholder='Alexandre'
                                        value={lName}
                                        onChange={(e) => setLName(e.target.value)}
                                        fullWidth={isMobile}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Box className={classes.separator}></Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: 'space-between',
                            width: "100%",
                            gap: isMobile ? "20px" : 0
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                alignItems: isMobile ? "flex-start" : "center",
                                width: isMobile ? "100%" : "auto",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    marginBottom: isMobile ? "5px" : 0
                                }}>{t('Establishment')} :</Typography>
                                <TextField
                                    id="establishment"
                                    variant="outlined"
                                    className={classes.ShortTextField}
                                    inputProps={{ style: { color: '#FFFFFF', } }}
                                    placeholder='Nomad'
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    fullWidth={isMobile}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                alignItems: isMobile ? "flex-start" : "center",
                                width: isMobile ? "100%" : "30%",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    width: isMobile ? "auto" : "15%",
                                    marginBottom: isMobile ? "5px" : 0
                                }}>{t('Type')} :</Typography>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    className={classes.ShortTextField}
                                    value={type}
                                    onChange={(event) => setType(event.target.value as string)}
                                    sx={{
                                        border: "none",
                                        width: isMobile ? "100%" : "37%",
                                        color: "#7B7B7B",
                                        fontStyle: "italic",
                                        borderRadius: "10px",
                                        "& fieldset": {
                                            border: "1px solid #AFAFAF",
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#7B7B7B",
                                            fontWeight: 300,
                                        },
                                        "& .MuiSvgIcon-root": {
                                            color: "#7B7B7B",
                                        },
                                        "& .MuiList-root": {
                                            color: "#7B7B7B"
                                        },
                                        "& .MuiMenuItem-root.Mui-selected:hover": {
                                            color: "#7B7B7B"
                                        },
                                        "& .css-1o0awoo-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                            color: "#7B7B7B",
                                            fontFamily: "Inter",
                                            fontWeight: 300,
                                            fontSize: "13px",
                                        }
                                    }}
                                >
                                    <MenuItem value="Chicha">Chicha</MenuItem>
                                    <MenuItem value="type">Type</MenuItem>
                                    <MenuItem value="Type 1">Type 1</MenuItem>
                                    <MenuItem value="Type 2">Type 2</MenuItem>
                                </Select>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                alignItems: isMobile ? "flex-start" : "center",
                                width: isMobile ? "100%" : "auto",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    width: isMobile ? "auto" : "28%",
                                    marginBottom: isMobile ? "5px" : 0
                                }}>{t('Address')} : </Typography>
                                <TextField
                                    id="address"
                                    variant="outlined"
                                    className={classes.LongTextField}
                                    inputProps={{ style: { color: '#FFFFFF', } }}
                                    placeholder='75 rue de Wazemmes 59000 Lille'
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    fullWidth={isMobile}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.separator}></Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: 'space-between',
                            width: "100%",
                            gap: isMobile ? "20px" : 0
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                alignItems: isMobile ? "flex-start" : "center",
                                width: isMobile ? "100%" : "auto",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    marginBottom: isMobile ? "5px" : 0
                                }}>{t('Email')} : </Typography>
                                <TextField
                                    id="email"
                                    variant="outlined"
                                    className={classes.LongTextField}
                                    inputProps={{ style: { color: '#FFFFFF', } }}
                                    placeholder='contact@nomad.fr'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth={isMobile}
                                />
                            </Box>
                            {!isMobile && <Box className={classes.separatorVertical}></Box>}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                alignItems: isMobile ? "flex-start" : "center",
                                width: isMobile ? "100%" : "auto",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    width: isMobile ? "auto" : "34%",
                                    marginBottom: isMobile ? "5px" : 0
                                }}>{t('Phone')} :</Typography>
                                <TextField
                                    id="phone"
                                    variant="outlined"
                                    className={classes.LongTextField}
                                    inputProps={{ style: { color: '#FFFFFF', } }}
                                    placeholder='0659404514'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    fullWidth={isMobile}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.separator}></Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: 'space-between',
                            width: "100%",
                            gap: isMobile ? "20px" : 0
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                alignItems: isMobile ? "flex-start" : "center",
                                width: isMobile ? "100%" : "auto",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    width: isMobile ? "auto" : "100%",
                                    marginBottom: isMobile ? "5px" : 0
                                }}>{t('Subscription')} : </Typography>
                                <Button
                                    sx={{
                                        padding: '5px 24px',
                                        borderRadius: '6px',
                                        border: "1px solid #ADADAD",
                                        background: "#1B1B1B",
                                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                        position: "relative",
                                        overflow: "hidden",
                                        color: "#FABD45",
                                        '&:hover': {
                                            background: '#FFFFFF',
                                            color: '#FABD45',
                                            cursor: 'pointer',
                                        },
                                        width: isMobile ? "100%" : "auto",
                                    }}
                                    onClick={() => navigate("/home/subscriptionFinal")}
                                >
                                    {tag}
                                </Button>
                            </Box>
                            {!isMobile && <Box className={classes.separatorVertical} sx={{ marginRight: "16px" }}></Box>}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: isMobile ? "flex-start" : "flex-end",
                                width: isMobile ? "100%" : "auto",
                            }}>
                                <Typography variant="subtitle2" sx={{
                                    color: '#FFFFFF',
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                }}>
                                    {t('PaymentType')}  : Carte de débit
                                </Typography>
                                <Button sx={{
                                    color: '#FFFFFF',
                                    textDecoration: 'underline',
                                    fontSize: "9px",
                                    padding: 0,
                                    minWidth: 'auto',
                                    alignSelf: isMobile ? "flex-start" : "flex-end"
                                }}>Changer</Button>
                            </Box>
                        </Box>
                        <Box className={classes.separator}></Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: 'space-between',
                            alignItems: isMobile ? "flex-start" : "center",
                            width: "100%",
                            gap: isMobile ? "20px" : 0
                        }}>
                            <Typography variant="body1" sx={{ color: 'white', marginTop: isMobile ? 0 : 2 }}>
                                {t('Language')} :
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: isMobile ? 'flex-start' : 'space-around',
                                mt: isMobile ? 1 : 0,
                                gap: isMobile ? "20px" : "136px",
                                width: isMobile ? "100%" : "auto"
                            }}>
                                <img id="fr" src={French} alt="French" style={{
                                    height: "25px",
                                    width: "25px",
                                    borderRadius: '100%',
                                    cursor: 'pointer',
                                    boxShadow: lang === "fr" ? '0px 0px 16px 0px #f6f6f6' : ''
                                }} onClick={(e) => {
                                    setLang("fr");
                                    i18n.changeLanguage("fr");
                                }} />
                                <img id="en" src={British} alt="British" style={{
                                    height: "25px",
                                    width: "25px",
                                    borderRadius: '100%',
                                    cursor: 'pointer',
                                    boxShadow: lang === "en" ? '0px 0px 16px 0px #f6f6f6' : ''
                                }} onClick={(e) => {
                                    setLang("en");
                                    i18n.changeLanguage("en");
                                }} />
                                <img id="es" src={Spanish} alt="Spanish" style={{
                                    height: "25px",
                                    width: "25px",
                                    borderRadius: '100%',
                                    cursor: 'pointer',
                                    boxShadow: lang === "es" ? '0px 0px 16px 0px #f6f6f6' : ''
                                }} onClick={(e) => {
                                    setLang("es");
                                    i18n.changeLanguage("es");
                                }} />
                            </Box>
                            <Button variant="contained" sx={{
                                padding: '6px 24px',
                                borderRadius: '45px',
                                border: "1px solid #ADADAD",
                                background: "#171717",
                                boxShadow: "none",
                                width: isMobile ? "100%" : "auto"
                            }}>{lang}</Button>
                        </Box>
                    </Box>
                </Box>
            </Card>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                mt: 4,
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? "20px" : 0
            }}>
                <Button variant="contained" sx={{
                    padding: '6px 24px',
                    borderRadius: '45px',
                    border: "0.6px solid #C9C9C9",
                    background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                    boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                    position: "relative",
                    overflow: "hidden",
                    '&:hover': {
                        background: '#FFFFFF',
                        color: '#000000',
                        cursor: 'pointer',
                    },
                    width: isMobile ? "100%" : "auto"
                }}>
                    <LayerComponent />
                    <Box sx={{
                        position: 'relative',
                        zIndex: '1',
                    }} onClick={() => navigate('/home/main')}>
                        {t('Cancel')}
                    </Box>
                </Button>
                <Button variant="contained" sx={{
                    padding: '6px 24px',
                    borderRadius: '45px',
                    border: "0.6px solid #C9C9C9",
                    background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                    boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                    position: "relative",
                    overflow: "hidden",
                    '&:hover': {
                        background: '#FFFFFF',
                        color: '#000000',
                        cursor: 'pointer',
                    },
                    width: isMobile ? "100%" : "auto"
                }}>
                    <LayerComponent />
                    <Box sx={{
                        position: 'relative',
                        zIndex: '1',
                    }} onClick={handleUpdateProfile}>
                        {t('ConfirmChanges')}
                    </Box>
                </Button>
            </Box>
        </Box>
    );
}

export default ProfilPage;