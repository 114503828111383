import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Theme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import SportsServices from '../Services/SportsServices';
import MainLeagueServices from '../Services/MainLeagueServices';
import { useDispatch } from 'react-redux';
import { setSelectedSport, setSelectedLeague, setSelectedLeagueId } from '../Store/Slice/selectedLeagueSlice';
import { useTranslation } from 'react-i18next';

interface SportsDataResponse {
    data: sportMainData[];
}

interface sportMainData {
    sport_img: string;
    title: string;
    description: string;
    sport_key: string;
}

interface League {
    league_desc: string;
    sport_key: string;
    league_key: string;
    league_banner: string;
    id: string;
}

interface LeagueResponse {
    data: League[];
}

const CreateContent: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);
    const [sportsData, setSportsData] = useState<SportsDataResponse>({ data: [] });
    const [loading, setLoading] = useState(true);
    const [sportLeagues, setSportLeagues] = useState<LeagueResponse>({ data: [] });
    const [leaguesLoading, setLeaguesLoading] = useState(true);
    const [sportKey, setSportKey] = useState<string>("");

    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

    const limit = 20;
    const pageNo = 1;
    const sportLeagueLimit = 500;
    const sportLeaguePage = 1;

    useEffect(() => {
        SportsServices.getSportData(pageNo, limit)
            .then(res => {
                setSportsData(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (sportKey) {
            setLeaguesLoading(true);
            MainLeagueServices.getMainLeagues(sportKey, sportLeaguePage, sportLeagueLimit)
                .then(res => {
                    setSportLeagues(res.data);
                    setLeaguesLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching sport leagues:', err);
                    setLeaguesLoading(false);
                });
        }
    }, [sportKey]);

    const handleCardClick = (sport_key: string, league_key: string, id: string) => {
        dispatch(setSelectedSport(sport_key));
        dispatch(setSelectedLeague(league_key));
        dispatch(setSelectedLeagueId(id));
        navigate('/home/ligues');
    };

    const handleAccordionChange = (isExpanded: boolean) => {
        setAccordionExpanded(isExpanded);
        if (!isExpanded) {
            setSelectedCategory(null);
        }
    };

    const getGridColumns = () => {
        if (isSmallScreen) return 12;
        if (isMediumScreen) return 6;
        return 3;
    };

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: { xs: 1, sm: 2, md: 3 },
            ml: { xs: 0, sm: '240px' },
            backgroundColor: 'rgb(15, 15, 15)',
            color: 'white',
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            mt: { xs: '45px', sm: 0 },  // Add this line
        }}>
            <Accordion
                sx={{
                    backgroundColor: '#191919',
                    color: 'white',
                    borderRadius: "15px",
                    boxShadow: "none",
                    overflow: 'hidden',
                   
                }}
                expanded={accordionExpanded}
                onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h3" sx={{ fontFamily: "BlairITC", lineHeight: { xs: "28px", md: "32px" }, fontSize: { xs: "16px", md: "18px" }, textTransform: 'uppercase' }}>{t('Sports')}</Typography>
                        <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: { xs: "8px", md: "10px" }, color: "#ffffffdb", textTransform: 'uppercase' }}>{t('ChooseYourDiscipline')}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {sportsData?.data?.map((card, index) => (
                            <Grid item xs={getGridColumns()} key={index}>
                                <Card
                                    sx={{
                                        backgroundImage: `url('${process.env.REACT_APP_FILE_URL}/sportImg/${card?.sport_img}')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: { xs: '80px', sm: '100px', md: '120px' },
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        position: 'relative',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setSportKey(card?.sport_key);
                                        setAccordionExpanded(false);
                                    }}
                                >
                                    <Box sx={{
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '100%',
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        background: "linear-gradient(270deg, rgba(0, 67, 145, 0) 25.07%, rgba(0, 20, 43, 0.82) 58.79%)",
                                        position: "relative"
                                    }}>
                                        <Box sx={{ width: "100%", padding: "8px" }}>
                                            <Typography sx={{ fontFamily: "Wedding Gothic ATF", fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "#FFFFFF" }} component="div">
                                                {card?.title}
                                            </Typography>
                                            <Typography sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: { xs: "6px", sm: "7px", md: "8px" } }}>
                                                {card?.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ backgroundColor: '#191919', borderRadius: "10px", padding: "8px 16px 16px" }}>
                {sportKey ? (
                    <>
                        <Box sx={{ marginBottom: "17px" }}>
                            <Typography variant="h3" sx={{ mb: "2px", fontFamily: "BlairITC", lineHeight: { xs: "28px", md: "32px" }, fontSize: { xs: "16px", md: "18px" }, textTransform: 'uppercase' }}>{t('ChampionshipsCups')}</Typography>
                            <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: { xs: "8px", md: "10px" }, color: "#ffffffdb", textTransform: 'uppercase' }}>{t('ChooseYourCompetition')}</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            {sportLeagues.data && sportLeagues.data.length > 0 ? sportLeagues.data?.map((card) => (
                                <Grid item xs={getGridColumns()} key={card?.id}>
                                    <Card
                                        onClick={() => handleCardClick(card?.sport_key, card?.league_key, card?.id)}
                                        sx={{
                                            backgroundImage: `url('${process.env.REACT_APP_FILE_URL}/banner/${card?.league_banner}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: { xs: '140px', sm: '160px', md: '200px' },
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ fontFamily: "Wedding Gothic ATF", fontSize: { xs: "14px", sm: "16px", md: "18px" }, color: "#FFFFFF" }}>
                                                {card?.league_desc}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) : (
                                <Grid container spacing={2} sx={{ height: "350px", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                                    <Typography sx={{ mb: 2, display: "flex", fontFamily: "Wedding Gothic ATF", fontSize: { xs: "16px", sm: "18px", md: "20px" }, textTransform: 'uppercase' }}>{t('NoLeaguesAvailable')}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography variant="h3" sx={{ mb: "2px", fontFamily: "BlairITC", lineHeight: { xs: "28px", md: "32px" }, fontSize: {  xs: "16px", md: "18px"}, textTransform: 'uppercase' }}>{t('ChampionshipsCups')}</Typography>
                            <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: { xs: "8px", md: "10px" }, color: "#ffffffdb", textTransform: 'uppercase' }}>{t('ChooseYourCompetition')}</Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ height: "350px", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography sx={{ mb: 2, display: "flex", fontFamily: "Wedding Gothic ATF", fontSize: { xs: "16px", sm: "18px", md: "18px" } }}>{t('SELECTASPORT')}</Typography>
                        </Grid>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default CreateContent;