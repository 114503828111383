import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, IconButton, Grid, useTheme, useMediaQuery } from '@mui/material';
import DL from '../../Assets/Images/DL.png';
import MultimediaGetServices from '../../Services/MultimediaGetServices';
import { useTranslation } from 'react-i18next';

interface Record {
  start: string;
  end: string;
  competitor1: {
    name: string;
    profile_img: string;
  };
  competitor2: {
    name: string;
    profile_img: string;
  };
  story_img: string;
  web_img: string;
  [key: string]: any;
}

interface GroupedData {
  _id: string;
  records: Record[];
}

const MediathequePage = () => {
  const [multimediaData, setMultimediaData] = useState<GroupedData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const timeZone: string = localStorage.getItem('timeZone') || '';
  const startDate = "2024-05-15T18:30:00Z";
  const endDate = new Date(new Date().setDate(new Date().getDate() + 90)).toISOString();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    MultimediaGetServices.getMultimediaData(startDate, endDate, timeZone)
      .then(res => {
        const data = res.data;
        setMultimediaData(data?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleDownloadStory = async (url: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = url.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleDownloadSquare = async (url: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = url.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const optionsDay: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const optionsDate: Intl.DateTimeFormatOptions = { day: 'numeric' };
    const optionsMonth: Intl.DateTimeFormatOptions = { month: 'long' };

    const formattedDay = date.toLocaleDateString('en-US', optionsDay);
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedMonth = date.toLocaleDateString('en-US', optionsMonth);

    return { day: formattedDay, date: formattedDate, month: formattedMonth };
  };

  const formatDateTime = (dateString: string, timeZone: string) => {
    const date = new Date(dateString);
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone
    };
    return new Intl.DateTimeFormat('en-US', optionsTime).format(date);
  };

  return (
    <Box component="main" sx={{ 
      flexGrow: 1, 
      p: isMobile ? 2 : 3, 
      ml: isMobile ? 0 : '240px', 
      backgroundColor: '#191919', 
      color: 'white' ,
      mt: { xs: '45px', sm: 0 },  // Add this line
    }}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Typography variant="h3" sx={{ 
          fontFamily: "BlairITC", 
          lineHeight: isMobile ? "28px" : "32px",
          fontSize: isMobile ? "24px" : "inherit"
        }}>{t('MEDIA')}</Typography>
        <Typography variant="h4" sx={{ 
          marginBottom: "0", 
          lineHeight: isMobile ? "16px" : "8px", 
          fontFamily: "Wedding Gothic ATF",
          fontSize: isMobile ? "16px" : "inherit"
        }}>{t('DOWNLOADYOURCONTENT')}</Typography>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : multimediaData?.length === 0 ? (
        <Typography sx={{  color: "#FFFFFF" }} >No data found</Typography>
      ) : (
        multimediaData?.map((matchDay, index) => {
          const { day, date, month } = formatDate(matchDay?._id);
          return (
            <Box key={index} sx={{
              marginBottom: 4, 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              backgroundColor: "#111111",
              padding: isMobile ? "10px" : "20px", 
              borderRadius: "15px", 
              gap: isMobile ? "20px" : "40px",
            }}>
              <Box sx={{ 
                display: "flex", 
                flexDirection: isMobile ? "row" : "column", 
                width: isMobile ? "100%" : "100px",
                justifyContent: isMobile ? "space-between" : "flex-start",
                alignItems: isMobile ? "center" : "flex-start",
                marginBottom: isMobile ? "10px" : 0
              }}>
                <Typography variant="h4" sx={{ 
                  marginBottom: isMobile ? 0 : "25px", 
                  lineHeight: isMobile ? "normal" : "1px", 
                  fontFamily: "Wedding Gothic ATF", 
                  marginTop: isMobile ? 0 : "10px",
                  fontSize: isMobile ? "18px" : "inherit"
                }}>
                  {day}
                </Typography>
                <Typography sx={{ 
                  marginBottom: isMobile ? 0 : "23px", 
                  lineHeight: isMobile ? "normal" : "1px", 
                  fontFamily: "Wedding Gothic ATF",
                  fontWeight: 350, 
                  fontSize: isMobile ? "32px" : "44px"
                }}>
                  {date}
                </Typography>
                <Typography variant="h4" sx={{ 
                  lineHeight: isMobile ? "normal" : "1px", 
                  fontFamily: "Wedding Gothic ATF",
                  fontSize: isMobile ? "18px" : "inherit"
                }}>
                  {month}
                </Typography>
              </Box>
              <Grid container spacing={isMobile ? 1 : 2}>
                {matchDay?.records?.map((event: Record, eventIndex: number) => {
                  const startTime = formatDateTime(event.start, timeZone);
                  const endTime = formatDateTime(event.end, timeZone);
                  return (
                    <Grid item xs={12} key={eventIndex}>
                      <Card sx={{ 
                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", 
                        color: 'white', 
                        height: isMobile ? "auto" : "62px", 
                        borderRadius: '12px', 
                        boxShadow: "0px 4px 4px 0px #00000040", 
                        border: '0.5px solid #2D2D2D' 
                      }}>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: isMobile ? 'column' : 'row',
                          alignItems: isMobile ? 'flex-start' : 'center', 
                          justifyContent: "space-between", 
                          padding: isMobile ? "10px" : "0px 13px", 
                          boxShadow: "0px 2px 3px 0px #0B0B0B63 inset" 
                        }}>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: isMobile ? "10px" : "20px",
                            marginBottom: isMobile ? "10px" : 0
                          }}>
                            <Box sx={{
                              display: 'flex', 
                              alignItems: 'center', 
                              padding: isMobile ? "5px 10px" : "0 15px 0px 15px", 
                              background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", 
                              borderRadius: '6px',
                              border: '1px solid #2D2D2D', 
                              cursor: 'pointer'
                            }} onClick={() => handleDownloadStory(`${process.env.REACT_APP_FILE_URL}/banner/${event?.story_img}`)}>
                              <IconButton color="primary" size={isMobile ? "small" : "medium"}>
                                <img src={DL} alt="IconImg" style={{ width: isMobile ? "14px" : "18px", height: isMobile ? "12px" : "16px" }} />
                              </IconButton>
                              <Typography variant="body2" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>{t('Story')}</Typography>
                            </Box>
                            <Box sx={{
                              display: 'flex', 
                              alignItems: 'center', 
                              padding: isMobile ? "5px 10px" : "0 15px 0px 15px", 
                              background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", 
                              borderRadius: '6px',
                              border: '1px solid #2D2D2D',
                              cursor: 'pointer'
                            }} onClick={() => handleDownloadSquare(`${process.env.REACT_APP_FILE_URL}/banner/${event?.web_img}`)}>
                              <IconButton color="primary" size={isMobile ? "small" : "medium"}>
                                <img src={DL} alt="IconImg" style={{ width: isMobile ? "14px" : "18px", height: isMobile ? "12px" : "16px" }} />
                              </IconButton>
                              <Typography variant="body2" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>{t('Square')}</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: isMobile ? 'flex-start' : 'center', 
                            width: isMobile ? "100%" : "32%",
                            marginBottom: isMobile ? "10px" : 0
                          }}>
                            <Box sx={{ 
                              flexGrow: 1, 
                              width: isMobile ? "100%" : "80%", 
                              display: "flex", 
                              flexDirection: "column" 
                            }}>
                              <Typography variant="body2" sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: isMobile ? "10px" : "11px" }}>{event.competitor1.name}</Typography>
                              <Typography variant="body2" sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: isMobile ? "10px" : "11px" }}>{event.competitor2.name}</Typography>
                            </Box>
                            <Box sx={{ width: isMobile ? "100%" : "50%", marginTop: isMobile ? "5px" : 0 }}>
                              <Typography variant="caption" sx={{ color: "#4D4D4D", fontSize: isMobile ? '10px' : '12px' }}>{startTime} - {endTime}</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ 
                            borderBottom: 'none', 
                            display: "flex", 
                            width: isMobile ? "100%" : "40%", 
                            justifyContent: "space-between" 
                          }}>
                            <img
                              src={
                                event?.competitor1?.profile_img
                                  ? `${process.env.REACT_APP_FILE_URL}/logo/${encodeURIComponent(event.competitor1.profile_img)}`
                                  : '/path/to/default-image.png'
                              }
                              alt={`${event?.competitor1?.name || 'Competitor'} logo`}
                              style={{
                                width: isMobile ? "48%" : "198px",
                                height: isMobile ? "30px" : "62px",
                                marginRight: isMobile ? 0 : 10,
                                objectFit: 'cover',
                              }}
                            />
                            <img 
                              src={
                                event?.competitor2?.profile_img
                                  ? `${process.env.REACT_APP_FILE_URL}/logo/${encodeURIComponent(event.competitor2.profile_img)}`
                                  : '/path/to/default-image.png'
                              }
                              alt={`${event?.competitor2?.name || 'Competitor'} logo`} 
                              style={{ 
                                width: isMobile ? "48%" : "198px", 
                                height: isMobile ? "30px" : "62px", 
                                objectFit: 'cover' 
                              }} 
                            />
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default MediathequePage;