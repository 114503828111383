import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckImg1 from '../../Assets/Images/Check.png';
import CheckImg2 from '../../Assets/Images/Check1.png';
import LayerComponent from './LayerComponent';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { postSubscriptionData } from '../../Store/action';

import { AppDispatch } from '../../Store/Store';
import { enqueueSnackbar } from 'notistack';
import SubscriptionPlansServices from '../../Services/SubscriptionPlansServices';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
        justifyContent: 'center',
    },
    card: {
        width: '258px',
        maxWidth: '400px',
        border: '0.8px solid #303030',
        borderRadius: '11px',
        height: '383px',
        padding: '20px',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
        color: '#FFFFFF',
        position: "relative",
        overflow: "hidden",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },
    featureItem: {
        display: 'flex',
        alignItems: 'flex-start', // Changed from 'center' to 'flex-start'
        gap: '14px',
        marginBottom: '20px', // Reduced from 30px to 20px for tighter spacing
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '-52px',
        left: '50%',
        transform: 'translate(-50%)',
        marginTop: 'auto', // Push button to the bottom
        // paddingBottom: '20px', // Add some padding at the bottom

    },
    button: {
        padding: '8px 24px',
        borderRadius: '45px',
        border: '1px solid #363636',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
        boxShadow: "none",
    },
    premiumButton: {
        border: '1px solid #363636',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
    },
    separator: {
        borderBottom: '1px solid #FFFFFF',
        margin: '18px 0',
    },
}));

interface EstablishmentData {
    logo: string;
    type: string;
    activity: string;
    address: string;
    company_name: string;
    tva_number: string;
}

interface SignUpData {
    f_name: string;
    l_name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword?: string;
    lang: string;
    device_id: string;
    establishment: EstablishmentData;
}

interface SignUpProps {
    signUpData: Partial<SignUpData>;
    logoImg: File | null;
    setMemberData: React.Dispatch<React.SetStateAction<Partial<subscription>>>;
}

interface FileUpload {
    data: string[];
}
interface subscription {
    tag: string;
    price: number;
    validity: number;
    features: features[];
    img?: string;
    access_level: number;
}
interface features {
    title: string;
    description: string;
}
interface subscriptionState {
    data: subscription[];
}

const SubscriptionFinal = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [subscriptionPlans, setSubscriptionPlans] = useState<subscriptionState>({ data: [] });
    const [loading, setLoading] = useState(true);
    const [showPayPal, setShowPayPal] = useState(true);

    const userData = localStorage.getItem("user");
    const userId = userData ? JSON.parse(userData)?.data?.id : '';

    useEffect(() => {
        SubscriptionPlansServices.getSubscriptionPlanData()
            .then(res => {
                setSubscriptionPlans(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);
    console.log(subscriptionPlans.data)

    const handleFreeClick = async (membership: subscription) => {
        console.log(membership)
        const subscriptionData = {
            "user_id": userId,
            "tag": membership.tag,
            "price": membership.price,
            "validity": membership.validity,
            "access_level": membership.access_level,
            "features": membership.features,
        };
        try {
            await dispatch(postSubscriptionData(subscriptionData)).unwrap();
            enqueueSnackbar('Onboarding Successful', { variant: 'success' });
            navigate('/home');
        } catch (err) {
            console.log(err)
            if (err instanceof Error) {
                console.error('Error:', err.message);
                enqueueSnackbar(err.message, { variant: 'error' });
            } else {
                console.error('An unexpected error occurred');
                enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
            }
        }
    };

    const handlePremiumClick = async (membership: subscription) => {
        try {
            navigate('/home/Profil/PayPalButton', { state: { subscription: membership, userId } });
        } catch (error: any) {
            console.log(error.message);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
            ml: { xs: 0, sm: '240px' },
            backgroundColor: 'rgb(15, 15, 15)',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            minHeight: '100vh',
        }}>
            <Box sx={{ background: "#191919", padding: { xs: "16px", sm: "32px" }, borderRadius: "30px" }}>
                <Box sx={{
                    py: { xs: 2, sm: 4 },
                    px: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '50px'
                }}>
                    <Box className={classes.separator} sx={{ marginBottom: "-35px" }}></Box>
                    <Box className={classes.container} sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'stretch', sm: 'center' },
                    }}>
                        {subscriptionPlans && subscriptionPlans.data.map((membership, index) => (
                            <Box key={index} className={classes.card} sx={{
                                width: { xs: '100%', sm: '258px' },
                                maxWidth: { xs: '100%', sm: '400px' },
                                mb: { xs: 3, sm: 0 },
                            }}>
                                <LayerComponent />
                                <Box sx={{ zIndex: '1', position: 'relative', height: '74%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ mb: 2, flex: 1 }}>
                                        {membership.features.map((feature, idx) => (
                                            <Box key={idx} className={classes.featureItem}>
                                                <img src={membership?.img || (membership.tag === 'Premium' ? CheckImg2 : CheckImg1)} alt={`Check ${idx + 1}`} height="15px" width="19px" />
                                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                    <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: { xs: '12px', sm: '14px' } }}>{feature.title}</Typography>
                                                    <Typography variant="body2" sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: { xs: '6px', sm: '8px' } }}>{feature.description}</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Typography variant="body1" sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        textAlign: "center",
                                    }}>$ {membership.price}</Typography>
                                    <Box className={classes.buttonContainer} sx={{
                                        position: { xs: 'static', sm: 'absolute' },
                                        mt: { xs: 2, sm: 0 },
                                    }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                padding: '6px 24px',
                                                borderRadius: '45px ',
                                                border: "0.6px solid #2D2D2D",
                                                background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                                boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                                position: "relative",
                                                overflow: "hidden",
                                                '&:hover': {
                                                    background: '#FFFFFF',
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                },
                                                fontSize: { xs: '0.8rem', sm: '10px' },
                                            }}
                                            onClick={membership.tag === 'Free' ? () => handleFreeClick(membership) : () => handlePremiumClick(membership)}
                                        >
                                            <LayerComponent />
                                            <Box sx={{
                                                position: 'relative',
                                                zIndex: 1,
                                                ...(membership.tag === 'Premium' && {
                                                    background: "linear-gradient(180deg, #F9E785 0%, #A87A20 100%)",
                                                    backgroundClip: "text",
                                                    textFillColor: "transparent",
                                                    boxShadow: "none",
                                                }),
                                            }}>
                                                {membership.tag}
                                            </Box>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.separator}></Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SubscriptionFinal;