import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LeagueServices from '../Services/LeagueServices';
import { useDispatch } from 'react-redux';
import { setSelectedSport, setSelectedLeague, setSelectedLeagueId } from '../Store/Slice/selectedLeagueSlice';
import ProfileServices from '../Services/ProfileServices';
import TimeZoneComponent from './TimeZoneComponent';

export interface League {
    league_banner: string;
    sport_key: string;
    league_key: string;
    league_name: string;
    id: string;
    league_hm_banner: string;
}

export interface LeagueResponse {
    data: League[];
}

const MainContent: React.FC = () => {
    const [randomLeagues, setRandomLeagues] = useState<LeagueResponse>({ data: [] });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [timeZone, setTimeZone] = useState<string>('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const limit = 5;

    useEffect(() => {
        LeagueServices.getRandomLeagues(limit)
            .then(res => {
                setRandomLeagues(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [limit]);

    useEffect(() => {
        if (timeZone) {
            localStorage.setItem('timeZone', timeZone);
        }
    }, [timeZone]);

    useEffect(() => {
        ProfileServices.getProfileData()
            .then(res => {
                localStorage.setItem("user_profile", JSON.stringify(res.data.data));
            })
            .catch((err) => {
                console.log(err)
            });
    }, []);

    const handleMatchSelect = (sport_key: string, league_key: string, id: string) => {
        dispatch(setSelectedSport(sport_key));
        dispatch(setSelectedLeague(league_key));
        dispatch(setSelectedLeagueId(id));
        navigate("/home/ligues");
    }

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: isMobile ? 2 : 3,
            ml: isMobile ? 0 : '240px',
            backgroundColor: '#0F0F0F',
            position: "relative",
            mt: { xs: '45px', sm: 0 },  // Add this line
        }}>
            <TimeZoneComponent setTimeZone={setTimeZone} />

            {loading ? (
                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
                    Loading...
                </Typography>
            ) : randomLeagues?.data?.length === 0 ? (
                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
                    No data found
                </Typography>
            ) : (
                randomLeagues?.data?.map((card, index) => {
                    const imageUrl = `${process.env.REACT_APP_FILE_URL}/banner/${encodeURIComponent(card?.league_hm_banner)}`;

                    return (
                        <Box
                            key={index}
                            sx={{
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                p: 2,
                                height: isMobile ? "200px" : {
                                    sm: "100px",
                                    md: "160px",
                                    lg: "230px",
                                    xl: "300px"
                                },
                                mb: 2,
                                borderRadius: '30px',
                                color: 'white',
                                textShadow: '1px 1px 4px rgba(0,0,0,0.8)',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: isMobile ? "space-between" : "flex-end",
                                cursor: "pointer",
                                flexDirection: isMobile ? "column" : "row",
                            }}
                            style={{backgroundImage:`url(${imageUrl})`}}
                            onClick={() => handleMatchSelect(card?.sport_key, card?.league_key, card?.id)}
                        >
                            {isMobile && (
                                <Typography variant="h5" sx={{ fontFamily: "Wedding Gothic ATF", alignSelf: "flex-start" }}>
                                    {card?.league_name}
                                </Typography>
                            )}
                            <Box sx={{
                                position: "relative",
                                width: isMobile ? "100%" : "460px",
                                display: "flex",
                                justifyContent: isMobile ? "center" : "flex-end",
                            }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: isMobile ? '80%' : '60%',
                                        padding: '8px 24px',
                                        borderRadius: '45px',
                                        border: '1px solid #2D2D2D',
                                        background: "#FFFFFF",
                                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                        position: "relative",
                                        overflow: "hidden",
                                        color: "#000000",
                                        '&:hover': {
                                            background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                            color: '#FFFFFF',
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    Crée tes visuels
                                </Button>
                            </Box>
                        </Box>
                    );
                })
            )}
        </Box>
    );
};

export default MainContent;