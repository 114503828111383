import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Card, CardMedia, CardActionArea, Typography, Button, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
import BackgroundServices from '../../Services/BackgroundServices';

interface IBackgroundImg {
    setSelectedBackground: (image: string) => void;
}
interface backgroundResponse {
    data: bgMainData[];
}
export interface bgMainData {
    img_url: string;
}

const BackgroundImg = ({ setSelectedBackground }: IBackgroundImg) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedImage, setSelectedImage] = useState<string | null>(location.state?.selectedBackground || null);
    const [backgroundData, setBackgroundData] = useState<backgroundResponse>({ data: [] });
    const [loading, setLoading] = useState(true);
    const limit = 10;
    const pageNo = 1;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        BackgroundServices.getBackgroundData(pageNo, limit,)
            .then(res => {
                setBackgroundData(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [limit]);

    const handleImageSelect = (image: string) => {
        setSelectedImage(image);
        setSelectedBackground(image);
    };

    const handleSave = () => {
        navigate('/home/ligues', { state: { selectedBackground: selectedImage } });
    };

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: isMobile ? 2 : 3,
            ml: isMobile ? 0 : '240px',
            backgroundColor: 'rgb(15, 15, 15)',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            height: '100vh',
            mt: { xs: '45px', sm: 0 },  // Add this line
        }}>
            <Box sx={{ 
                background: "radial-gradient(50% 50% at 50% 50%, #1A1A1A 0%, #0B0B0B 100%)", 
                padding: isMobile ? "16px" : "32px", 
                borderRadius: "30px" 
            }}>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between', 
                    mb: 2, 
                    alignItems: isMobile ? "flex-start" : "center",
                    gap: isMobile ? 2 : 0
                }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mb: isMobile ? 1 : 2 }}>
                        <Typography variant="h3" sx={{ 
                            fontFamily: "BlairITC", 
                            lineHeight: isMobile ? "34px" : "44px",
                            fontSize: isMobile ? "24px" : "inherit"
                        }}>LIGUE 1</Typography>
                        <Typography variant="h4" sx={{ 
                            marginBottom: "0", 
                            lineHeight: isMobile ? "16px" : "8px", 
                            fontFamily: "Wedding Gothic ATF", 
                            fontSize: isMobile ? "12px" : "10px", 
                            color: "#ffffffdb" 
                        }}>PERSONNALISE TES AFFICHES</Typography>
                    </Box>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{
                        padding: '6px 24px',
                        borderRadius: '45px',
                        border: "0.6px solid #2D2D2D",
                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                        position: "relative",
                        overflow: "hidden",
                        '&:hover': {
                            background: '#FFFFFF',
                            color: '#000000',
                            cursor: 'pointer',
                        },
                        height: 'fit-content',
                        width: isMobile ? '100%' : 'auto'
                    }}>Suivant</Button>
                </Box>
                <Grid container spacing={isMobile ? 1 : 2}>
                    {backgroundData.data?.map((image, index) => (
                        <Grid item xs={isMobile ? 6 : 2.4} key={index}>
                            <Card sx={{ height: "100%", width: "100%" }}>
                                <CardActionArea onClick={() => handleImageSelect(`"${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}"`)}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}`}
                                        alt={`image ${index + 1}`}
                                        sx={{
                                            borderRadius: '8px',
                                            border: selectedImage === `"${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}"` ? '2px solid blue' : 'none',
                                            position: 'relative',
                                            aspectRatio: '9/16',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    {selectedImage === `"${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}"` && (
                                        <FormControlLabel
                                            control={<Checkbox checked={true} />}
                                            label=""
                                            sx={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 10,
                                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    )}
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default BackgroundImg;