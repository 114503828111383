import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Button, useTheme, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../Pages/AuthPages/Login/Logo';
import Galary_Icon from "../Assets/Images/SideBarIcons/Galary_Icon.png";
import Home_icon from "../Assets/Images/SideBarIcons/Home_icon.png";
import Profile_Icon from "../Assets/Images/SideBarIcons/Profile_Icon.png";
import create_Icon from "../Assets/Images/SideBarIcons/create_Icon.png";
import { useTranslation } from 'react-i18next';
import AuthServices from '../Services/AuthServices';
import { enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const Sidebar: React.FC = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [userLogo, setUserLogo] = useState<string>('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        console.log(location.pathname)
        if (location.pathname === '/home') {
            navigate('/home/main');
            setActiveItem('/home/main');
        } else {
            if (location.pathname != '/home/ligues' && location.pathname != '/home/background') {
                localStorage.removeItem('selectedEvents');
            }
            setActiveItem(location.pathname);
        }
    }, [location, navigate]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleListItemClick = (path: string) => {
        setActiveItem(path);
        navigate(path);
        if (isMobile) {
            setMobileOpen(false);
        }
    };

    const getIconStyle = (path: string | string[]) => {
        const isActive = activeItem && (
            Array.isArray(path)
                ? path.includes(activeItem)
                : path === activeItem
        );

        return {
            height: '24px',
            width: '24px',
            opacity: isActive ? '100%' : '35%',
        };
    };

    const getIconStyleProfile = (path: string) => ({
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        padding: '2px',
        objectFit: 'contain' as const,
        opacity: activeItem === path ? '100%' : '35%',
    });

    const handleLogout = () => {
        AuthServices.customerLogOut()
            .then(res => {
                enqueueSnackbar('Successfully Logout', { variant: 'success' });
                navigate("/");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        const updateLogo = () => {
            const userData = localStorage.getItem("user_profile");
            const logo = userData ? JSON.parse(userData)?.establishment?.logo : '';
            setUserLogo(logo);
        };

        updateLogo();
        const timer = setTimeout(() => {
            updateLogo();
        }, 1000);

        window.addEventListener("storage", updateLogo);

        return () => {
            window.removeEventListener("storage", updateLogo);
            clearTimeout(timer);
        };
    }, []);

    const drawer = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}>
            <Box sx={{ textAlign: "center", marginBottom: "40px", cursor: "pointer" }} onClick={() => handleListItemClick('/home/main')}>
                <Logo />
            </Box>
            <List sx={{ flexGrow: 1 }}>
                {[
                    { path: '/home/main', icon: Home_icon, label: 'welcome' },
                    { path: '/home/create', icon: create_Icon, label: 'Create' },
                    { path: '/home/mediathequePage', icon: Galary_Icon, label: 'MediaLibrary' },
                    { path: '/home/Profil', icon: userLogo !== '' ? `${process.env.REACT_APP_FILE_URL}/logo/${userLogo}` : Profile_Icon, label: 'Profile' },
                ].map((item) => (
                    <ListItem
                        button
                        key={item.path}
                        onClick={() => handleListItemClick(item.path)}
                        sx={{
                            color: activeItem === item.path ? '#FFFFFF' : '#808080',
                            marginBottom: '10px',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                        }}
                    >
                        <ListItemIcon sx={{ color: activeItem === item.path ? '#FFFFFF' : '#808080', minWidth: '40px' }}>
                            <img
                                src={item.icon}
                                alt=""
                                style={item.path === '/home/Profil' ? getIconStyleProfile(item.path) : getIconStyle(item.path)}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t(item.label)} />
                    </ListItem>
                ))}
            </List>
            <Box sx={{
                marginTop: 'auto',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        padding: '10px 43px',
                        borderRadius: '30px',
                        border: "1px solid #8F8F8F",
                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                        boxShadow: "none",
                        width: '100%',
                        '&:hover': {
                            background: "radial-gradient(59.32% 80.35% at 50% 0%, #2A2A2A 0%, #1D1D1D 100%)",
                        },
                    }}
                    onClick={handleLogout}
                >
                    {t('Logout')}
                </Button>
            </Box>
        </Box>
    );

    return (
        <>
            {isMobile && (
                <IconButton
                    color="inherit"
                    aria-label={mobileOpen ? "close drawer" : "open drawer"}
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        display: { sm: 'none' },
                        position: 'fixed',
                        top: 10,
                        left: mobileOpen ? 'calc(60% - 2rem)' : 10, // Adjusted position
                        zIndex: 1300,  // Increased to be above the drawer
                        color: 'white !important'
                    }}
                >
                    {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
            )}
            <Drawer
                variant={isMobile ? "temporary" : "permanent"}
                open={isMobile ? mobileOpen : true}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#000000',
                        color: 'white',
                        width: isMobile ? '60%' : '240px',
                        padding: '20px',
                    }
                }}
            >
                {drawer}
            </Drawer>
        </>
    );
};

export default Sidebar;