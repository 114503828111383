import requests from "../Axios/Instance";

interface ActivityLogParams {
  action_by: string;     
  user_name: string;      
  description: string;     
  extra_info?: string;    
}

const ActivityLogsServices = {
  postActivityLog: async (body: ActivityLogParams) => {
    return requests.post("/activityLogs", body);
  },
};

export default ActivityLogsServices;
