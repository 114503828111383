import requests from "../Axios/Instance";

interface EstablishmentData {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}
interface SignUpData {
  f_name: string;
  l_name: string;
  email: string;
  phone: string;
  password: string;
  lang?: string;
  device_id: string;
  establishment: EstablishmentData;
}

interface SignInData {
  username: string;
  password: string;
  device_id: string;
}

// If `id` is a string, update `ById` to a more specific type
type ById = string;

interface ResponseData<T> {
  data: T;
  msg: string;
  // other fields if necessary
}
interface ForgotPasswordI {
  email:string
}
interface VerifyOTPI {
  email: string;    
  otp:string
}
const AuthServices = {
  customerSignUp: async (data: SignUpData): Promise<ResponseData<any>> => {
    try {
      return await requests.post(`/user/signup`, data);
    } catch (error) {
      console.error("Error during sign up:", error);
      throw error; // Propagate the error
    }
  },

  customerSignIn: async (data: SignInData): Promise<ResponseData<any>> => {
    try {
      return await requests.post(`/user/login`, data);
    } catch (error) {
      console.error("Error during sign in:", error);
      throw error; // Propagate the error
    }
  },
  customerLogOut: async (): Promise<ResponseData<any>> => {
    try {
      return await requests.post(`/user/logout`);
    } catch (error) {
      console.error("Error during sign in:", error);
      throw error; // Propagate the error
    }
  },
  getCustomerById: async (id: ById): Promise<ResponseData<any>> => {
    try {
      return await requests.get(`customer/${id}`);
    } catch (error) {
      console.error(`Error fetching customer with ID ${id}:`, error);
      throw error; // Propagate the error
    }
  },
  customerForgotPassword: async (data:ForgotPasswordI): Promise<ResponseData<any>> => {
    try {
      return await requests.post(`/user/forgot_password`,data);
    } catch (error) {
      console.error("Error during sign in:", error);
      throw error; // Propagate the error
    }
  },
  customerVerifyOTP: async (data:VerifyOTPI): Promise<ResponseData<any>> => {
    try {
      return await requests.post(`/user/otpVerify`,data);
    } catch (error) {
      console.error("Error during sign in:", error);
      throw error; // Propagate the error
    }
  },
};

export default AuthServices;
